import React from "react";
import { ArrowUp } from "react-bootstrap-icons";
import "./button-back-top.styles.scss"

const ButtonBackTop = () => {
    return(
        <button
        type="button"
        className="btn btn-sm"
        id="btn-back-to-top"
        >
  <i className="fas fa-arrow-up"><ArrowUp/></i>
</button>
    );
}

export default ButtonBackTop