import React from "react";
import Fade from "react-reveal/Fade";
import "../big-data-section/big-data-section.styles.scss";
import FeatureItem from "../feature-seccion/feature-item/feature-item.component";
import bigDataInfo from "./../../data/bigData.json";

const BigDataSection = () => {
  return (
    <div className="bigData-container">
      <div className="bigData-items">
        <Fade bottom small cascade>
          <div>
            <h2 className="title">Big Data</h2>
            <p>Mejores tecnologías de administración de datos y las habilidades de Ciencia de Datos, habilitan mejores maneras para la explotación de datos y ayudar a tener ventajas competitivas</p>
          </div>
        </Fade>
        <div className="row">
          {bigDataInfo.data.map((value, idx) => (
            <div className="col-lg-4 col-md-6 icon-box aos-init aos-animate text-center " key={`bigDataDiv-${idx}`}>
              <FeatureItem
                key={`bigData-${idx}`}
                img={value.img}
                title={value.title}
                text={value.text}
                flag={value.flag}
                features={value.features}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BigDataSection;
