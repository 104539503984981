import React from "react";
import PropTypes from "prop-types";
import "../CICD-item/CICD-item.styles.scss";
import Fade from "react-reveal/Fade";

const CICDItem = ({ idx, title, text }) => {
    idx = idx < 10 ? '0'+idx : idx;
  return (
    <Fade bottom normal cascade>
      <div className="cicd-item">
        <div className="idx-cicd-item">{idx}</div>
        <h4>{title}</h4>
        <div className="text-cicd-item">{text}</div>
      </div>
    </Fade>
  );
};

CICDItem.propTypes = {
  idx: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};
export default CICDItem;
