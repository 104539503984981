import React from "react";
import PropTypes from "prop-types";
import { Fade } from "react-reveal";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "./gallery-item.styles.scss"
const GalleryItem = ({img, name}) => {
    return(
      <Fade bottom small cascade>
          <div className="gallery-item-container">
            <LazyLoadImage src={img} alt={name}/>
        </div>
      </Fade>
    )
}

GalleryItem.propTypes = {
    name: PropTypes.string.isRequired,
    img: PropTypes.string.isRequired
}

export default GalleryItem