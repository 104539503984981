import React from "react";
import Fade from "react-reveal/Fade";
import FeatureItem from "../feature-seccion/feature-item/feature-item.component";
import "../our-values-section/our-values-section.style.scss";
import OurValuesData from "./../../data/ourValues.json";

const OurValuesSection = () => {
  return (
    <div className="ourValues-container">
      <Fade bottom small cascade>
        <div className="ourValues-items">
          <h2 className="title">Nuestros Valores</h2>
          <div className="row">
            {OurValuesData.data.map((value, idx) => (
              <div className="col-lg-6 col-md-6 icon-box aos-init aos-animate text-center " key={`ourValuesDiv-${idx}`}>
                <FeatureItem
                  key={`ourValues-${idx}`}
                  img={value.img}
                  title={value.title}
                  text={value.text}
                />
              </div>
            ))}
          </div>
        </div>
      </Fade>
    </div>
  );
};

export default OurValuesSection;
