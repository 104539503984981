import PropTypes from 'prop-types';
import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const AiTechnologies = ({ images }) => {

    const settings = {
        dots: true,
        lazyLoad: true,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        speed: 3000,
        autoplaySpeed: 3000,
        cssEase: "linear"
    };


    return (
        <Slider {...settings}>
            {images.map((value, idx) => (
                <div key={idx}>
                    <img src={value.src} alt={`technology-${idx}`} key={`img-${idx}`} />
                </div>
            ))}
        </Slider>
    )
};

AiTechnologies.propTypes = {
    images: PropTypes.arrayOf(PropTypes.shape({
        src: PropTypes.string.isRequired
    })).isRequired
};

export default AiTechnologies;
