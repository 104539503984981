import React from "react";
import GalleryData from "./../../data/clientsData.json"
import GalleryItem from "./gallery-item/gallery-item.component";
import "./gallery.styles.scss"
const Gallery = () => {

    return(
        <div className="gallery-container">
            {GalleryData.data.map((gallery, idx) =>(
                <GalleryItem
                key={`gallery-${idx}`}
                name={gallery.name}
                img={gallery.img}
                />
            ))}
        </div>
    )
}

export default Gallery