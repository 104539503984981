import React, { useEffect, useState } from "react";
import {
  EnvelopeFill,
  Facebook,
  Linkedin,
  List,
  PhoneFill,
  Twitter,
  X
} from "react-bootstrap-icons";
import { HashLink } from "react-router-hash-link";

import { LazyLoadImage } from "react-lazy-load-image-component";

import Iso27001 from "../../assets/images/iso27001_40.png";
import logo from "./../../assets/images/logo075.png";

import { Outlet } from "react-router-dom";
import ButtonFloat from "../button-float/button-float.component";
import FooterSection from "../footer-section/footer-section.component";
import "./navAction";

import "./nav-bar.styles.scss";

const NavigationBar = () => {
  const [isActive, setActive] = useState(false);
  const changeActive = () => {
    document.getElementsByClassName("mobile-nav-toggle")[0].style.right =
      "-27px";
    setActive((prev) => !prev);
  };
  const isBarChange = () => {
    if (isActive === true) {
      setActive((prev) => prev === false);
    } else {
      setActive((prev) => prev === true);
    }
  };

  useEffect(() => { }, [isActive]);
  return (
    <>
      <section id="topbar" className="d-flex align-items-center">
        <div className="container d-flex justify-content-center justify-content-md-between">
          <div className="contact-info d-flex align-items-center">
            <a
              href="mailto:info-mexico@afirma.solutions"
              target="blank"
              aria-label="email-icon"
            >
              <i>
                <EnvelopeFill />
              </i>
            </a>
            <a
              className="mailtext"
              href="mailto:info-mexico@afirma.solutions"
              aria-label="email"
              target="blank"
            >
              contacto@afirma.solutions
            </a>
            <a
              href="https://wa.me/525618445630"
              target="blank"
              aria-label="whatsapp-icon"
            >
              <i className="phone-icon">
                <PhoneFill />
              </i>
            </a>{" "}
            <a
              className="mailtext"
              href="https://wa.me/525618445630"
              aria-label="whatsapp"
              target="blank"
            >
              <text>(+52)5618445630</text>
            </a>
          </div>
          <div className="social-links">
            <div
              className="iso"
            >
              <img src={Iso27001} alt="ISO 27001" />
            </div>
            <a
              href="https://twitter.com/afirmasolutions"
              className="twitter"
              aria-label="twitter"
              target="blank"
            >
              <i>
                <Twitter />
              </i>
            </a>
            <a
              href="https://www.facebook.com/afirmasolutions/"
              className="facebook"
              aria-label="facebook"
              target="blank"
            >
              <i>
                <Facebook />
              </i>
            </a>
            <a
              href="https://www.linkedin.com/company/afirma-solutions/"
              className="linkedin"
              aria-label="linkedin"
              target="blank"
            >
              <i>
                <Linkedin />
              </i>
            </a>
          </div>
        </div>
      </section>

      <header id="header" className="d-flex align-items-center">
        <div className="container d-flex align-items-center">
          <div className="logo me-auto">
            <a href="#initial">
              <LazyLoadImage
                className="img-fluid"
                src={logo}
                alt="afirma-logo"
              />
            </a>
          </div>

          <nav
            id="navbar"
            className={isActive == true ? "navbar-mobile" : "navbar"}
          >
            <i className="x-icon" onClick={isBarChange}>
              <X />
            </i>
            <ul onClick={isBarChange}>
              <li>
                <HashLink className="nav-link scrollto active" to="/#initial">
                  Inicio
                </HashLink>
              </li>
              <li>
                <HashLink className="nav-link scrollto" to="/#bigData">
                  Big Data
                </HashLink>
              </li>
              <li>
                <HashLink className="nav-link scrollto" to="/#devops">
                  DevOps
                </HashLink>
              </li>
              <li>
                <HashLink className="nav-link scrollto" to="/#devopsAcademy">
                  Academia DevOps
                </HashLink>
              </li>
              <li>
                <HashLink className="nav-link scrollto" to="/#ai">
                  Inteligencia Artificial
                </HashLink>
              </li>
              <li>
                <HashLink
                  className="nav-link scrollto"
                  to="/servicios-financieros#financialServices"
                >
                  Consultoría Financiera
                </HashLink>
              </li>
              <li>
                <HashLink className="nav-link scrollto" to="/#location">
                  Contacto
                </HashLink>
              </li>
            </ul>
            <i className=" bi bi-list mobile-nav-toggle" onClick={changeActive}>
              <List />
            </i>
          </nav>
        </div>
      </header>

      <main id="main">
        <ButtonFloat />
        <Outlet />
      </main>
      <FooterSection />
    </>
  );
};

export default NavigationBar;
