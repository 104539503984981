import PropTypes from 'prop-types';
import React from 'react';
import "./ai-services.styles.scss";
import ServiceCard from './service-card/service-card.component';

const AiServices = ({ services }) => {
    return (
        <div className='ai-services-container'>
            {services.map((value, idx) => (
                <ServiceCard className='service-card' key={`service-${idx}`}
                    icon={value.icon} title={value.title} description={value.description} />
            ))}
        </div>
    )
};

AiServices.propTypes = {
    services: PropTypes.arrayOf(PropTypes.shape({
        icon: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired
    })).isRequired
};

export default AiServices;
