import React from "react";
//import { LazyLoadImage } from "react-lazy-load-image-component";
import { Fade } from "react-reveal";
import CICDSection from "../../layouts/CICD-section/CICD-section.component";
import AcademyDevops from "../../layouts/academy-devops/academy-devops.component";
import Ai from "../../layouts/ai/ai.component";
import BigDataSection from "../../layouts/big-data-section/big-data-section.component";
import CardAbout from "../../layouts/card-about/card-about.component";
import CardSection from "../../layouts/card-section/card-section.component";
import ContacCard from "../../layouts/contac-card/contac-card.component";
import DevOpsCard from "../../layouts/devops-card/devops-card.component";
import GalleryPartners from "../../layouts/gallery/gallery-partners/gallery-partners";
import Gallery from "../../layouts/gallery/gallery.component";
import OurValuesSection from "../../layouts/our-values-section/our-values-section.component";
import "./home.styles.scss";

const Home = () => {
  return (
    <div className="home-component container">
      <section id="initial" className="initial">
        <CardAbout />
      </section>
      <section id="ourValues" className="ourValues">
        <OurValuesSection />
      </section>
      <section id="bigData" className="bigData">
        <BigDataSection />
      </section>

      <section id="devops" className="devops">
        <h1 className="title">DevSecOps as a Services</h1>
        <p>
          Equipo de especialistas con el objetivo de ayudar a implementar un
          esquema DevOps en la organización.
        </p>
        <CardSection />
        <div className="dev-process">
          <p>DevSecOps: Desarrollo Seguro como Proceso de Mejora Continúa.</p>
        </div>
        <DevOpsCard />
      </section>
      <section id="cicd" className="cicd">
        <CICDSection />
      </section>
      <section id="devopsAcademy" className="academyDevops">
        <AcademyDevops />
      </section>
      <section id="ai" className="ai">
        <Ai />
      </section>
      <Fade bottom small cascade>
        <section id="clients" className="clients">
          <h1>Nuestras Referencias</h1>
          <Gallery />
        </section>
        <section id="partners" className="partners">
          <h1>Nuestros Partners</h1>
          <GalleryPartners />
        </section>
      </Fade>
      <Fade bottom small cascade>
        <section id="location" className="location">
          <div className="title">
            <h1>Contáctanos</h1>
          </div>
          <ContacCard />
        </section>
      </Fade>
    </div>
  );
};

export default Home;
