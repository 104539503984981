import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Fade from "react-reveal/Fade";
import diagrama from "./../../assets/images/DevSecOps.png";
import DevopsCardItem from "./devops-card-item/devops-card-item.component";
import "./devops-card.styles.scss";

const DevOpsCard = () => {
  let Items = [
    {
      title: "Servicio externalizado:",
      text: "Todo el servicio lo proporciona el equipo de Afirma",
    },
    {
      title: "Servicio Híbrido",
      text: "Esquema donde Afirma se integra al área de TI del Cliente, y hace un plan de traspaso de conocimiento.",
    },
  ];
  return (
    <div className="devops-container">
      <div className="image-section">
        <Fade bottom small cascade>
          <LazyLoadImage
            src={diagrama}
            alt="DevSecOps: Desarrollo Seguro como Proceso de Mejora Continua."
          />
        </Fade>
      </div>
      <div className="row text-center mt-5 mb-3">
        {Items.map((value, idx) => (
          <div
            className="col-md-5 content-item aos-init aos-animate m-4 text-center"
            key={`itemDiv-${idx}`}
          >
            <DevopsCardItem
              key={`img-cicd-${idx}`}
              title={value.title}
              text={value.text}
            ></DevopsCardItem>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DevOpsCard;
