import React from "react";
import PropTypes from "prop-types";
import "./academy-devops-item.styles.scss";

const AcademyDevopsItem = ({ idx, title }) => {
    //<div className="title-academyDevops-item col-lg-11 col-md-10">{title}</div>
  return (
      <div className="academyDevops-item row">
        <div className="idx-academyDevops-item col-lg-1 col-md-2">{idx}</div>
        <div className="title-academyDevops-item col-lg-1 col-md-5">{title}</div>
      </div>
  );
};

AcademyDevopsItem.propTypes = {
  idx: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired
};
export default AcademyDevopsItem;
