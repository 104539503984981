import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.scss";
import NavigationBar from "./layouts/nav-bar/nav-bar.component";
import Home from "./pages/home/home.component";
import SGIPolicy from "./pages/sgi-policy/sgi-policy.component";

function App() {
  const FinancialServices = lazy(() =>
    import("./pages/financial-services/financial-services.component")
  );
  const TermsConditions = lazy(() =>
    import("./pages/terms-conditions/terms-conditions.component")
  );

  const PrivacyPolicy = lazy(() =>
    import("./pages/privacy-policy/privacy-policy.component")
  );

  return (
    <div className="app-component">
      <Routes>
        <Route path="/" element={<NavigationBar />}>
          <Route index element={<Home />} />
          <Route
            path="/servicios-financieros"
            element={
              <Suspense>
                <FinancialServices />
              </Suspense>
            }
          />
          <Route
            path="/aviso-legal"
            element={
              <Suspense>
                <TermsConditions />
              </Suspense>
            }
          />
          <Route
            path="/politica-privacidad"
            element={
              <Suspense>
                <PrivacyPolicy />
              </Suspense>
            }
          />
          <Route
            path="/politica-sgi"
            element={
              <Suspense>
                <SGIPolicy />
              </Suspense>
            }
          />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
