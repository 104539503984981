import PropTypes from 'prop-types';
import React from 'react';
import "./service-card.styles.scss";

const ServiceCard = ({ idx, icon, title, description }) => {
    return (
        <div className="service-card" key={idx}>
            <div className="icon-container">
                <img src={icon} alt={title} />
            </div>
            <h3>{title}</h3>
            <p>{description}</p>
        </div>
    );
};

ServiceCard.propTypes = {
    idx: PropTypes.number.isRequired,
    icon: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
};
export default ServiceCard;
