import React from "react";
import "./academy-devops.styles.scss";
import CICDInfo from "../../data/CICDData.json";
import AcademyDevopsItem from "./academy-devops-item/academy-devops-item.component";
import DevopsCardItem from "../devops-card/devops-card-item/devops-card-item.component";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Fade from "react-reveal/Fade";

const AcademyDevops = () => {
  return (
    <div className="academydevops-container">
      <div className="academydevops-items">
            <h2 className="title">Academia DevOps</h2>
            <p className="text">Academia de formación DevOps, que habilitará al equipo de TI del cliente en la automatización de su infraestructura, generación de pipelines y migración de carga a la Nube.</p><br/>
            
            <div className="row">
                {CICDInfo.academy.map((value, idx) => (
                    <div className="col-lg-12 col-md-12 content-item aos-init aos-animate text-center p-0" key={`academydevopsDiv-${idx}`}>
                        <AcademyDevopsItem
                            key={`academydevops-${idx}`}
                            idx={idx+1}
                            title={value.title}
                        />
                    </div>
                ))}
            </div>

            <div className="row text-center mt-5 mb-3">
              <div className="col-md-12 col-md-12 content-item aos-init aos-animate p-0 m-4">
                  <Fade bottom small cascade><LazyLoadImage className="d-block w-75 image-academy" src={CICDInfo.imagesAcademy[0].src} alt="First image"/></Fade>
              </div>
            </div>

            <div className="row text-center mt-5 mb-3">
              {CICDInfo.itemsAcademy.map((value, idx) => (
                  <div className="col-md-5 content-item aos-init aos-animate m-4 text-center" key={`itemDiv-${idx}`}>
                      <DevopsCardItem key={`img-cicd-${idx}`} title={value.title} text={value.text}></DevopsCardItem>
                  </div>
                ))}
            </div>
            
      </div>
    </div>
  );
};

export default AcademyDevops;
