import React from "react";
import CardContentData from "./../../data/cardContent.json"
import "./../../components/description-card/description-card.component";
import "./card-about.styles.scss"
import DescriptionCard from "./../../components/description-card/description-card.component";

const CardAbout = () => {
    return (
        <div className="card-content-container">
            <div>
                {
                    CardContentData.dataAbout.map((card,idx) => (
                        <DescriptionCard
                        key={`card-${idx}`}
                        title={card.title}
                        description={card.description}
                        features={card.features}
                        img={card.img}
                        position={card.position}
                        />
                    ))
                }
            </div>
        </div>
    );
};

export default CardAbout;