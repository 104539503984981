import React, { useEffect, useState } from "react";
import ButtonBackTop from "../../components/button-back-top/button-back-top.component";

const ButtonFloat = () => {

    const mybutton = document.getElementById("btn-back-to-top");
    const [flag, setButtonFlag] = useState(false);
    mybutton?.addEventListener("click", backToTop);
    function backToTop() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      }
    useEffect(()=>{
        function handlerScroll(){
          if(document.body.scrollTop > 20 ||
            document.documentElement.scrollTop > 20){
              setButtonFlag(true);
          }
          else {
              setButtonFlag(false);
          }
        }
        window.addEventListener("scroll", handlerScroll);
        //se limpia la memoria en cada re-render
        return () => window.removeEventListener("scroll", handlerScroll);
    },[])
  
    return(
        <div>
            {
                (flag) ? <ButtonBackTop/> : null
            }
        </div>
    );
}

export default ButtonFloat