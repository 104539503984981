import React from "react";
import { Facebook, Linkedin, Twitter } from "react-bootstrap-icons";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { HashLink } from "react-router-hash-link";
import Camespcom from "../../assets/images/camespcom.png";
import Duns from "../../assets/images/duns.png";
import Iso27001 from "../../assets/images/iso27001_100.png";
import LogoAfirma from "../../assets/images/logo-afirma_trans.png";

import "./footer-section.styles.scss";

const FooterSection = () => {
  return (
    <div className="footer-container">
      <footer id="footer">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-2 col-md-3 footer-links">
                <div className="logo-duns">
                  <LazyLoadImage src={LogoAfirma} alt="Logo-Afirma" />
                  <a
                    href="https://www.dunsregistered.com/es/DUNS.aspx"
                    aria-label="duns-951588671"
                    target="_blank"
                    rel="noreferrer"
                    id="duns"
                  >
                    <LazyLoadImage src={Duns} alt="Logo-Afirma" />
                  </a>
                </div>
              </div>

              <div className="col-lg-3 col-md-3 footer-info">
                <div className="address">
                  <h3>Afirma Solutions</h3>
                  <p>Av.Ejercito Nacional #843 B </p>
                </div>
                <div className="social-links mt-3">
                  <a
                    href="https://twitter.com/afirmasolutions"
                    target="_blank"
                    aria-label="twitter"
                    rel="noreferrer"
                    className="twitter"
                  >
                    <Twitter />
                  </a>
                  <a
                    href="https://www.facebook.com/afirmasolutions/"
                    target="_blank"
                    aria-label="facebook"
                    rel="noreferrer"
                    className="facebook"
                  >
                    <Facebook />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/afirma-solutions/"
                    target="_blank"
                    aria-label="linkedin"
                    rel="noreferrer"
                    className="linkedin"
                  >
                    <Linkedin />
                  </a>
                </div>
                <div className="iso">
                  <LazyLoadImage src={Iso27001} alt="ISO 27001" />
                </div>
              </div>

              <div className="col-lg-2 col-md-3 footer-links">
                <h4>Links</h4>
                <ul>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <HashLink to="/#initial">Inicio</HashLink>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <HashLink to="/#main">Acerca de nosotros</HashLink>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <HashLink to="aviso-legal#termsconditions">
                      Aviso Legal
                    </HashLink>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <HashLink to="politica-privacidad#policy">
                      Política de Privacidad
                    </HashLink>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <HashLink to="politica-sgi#sgsi-policy">
                      Política SGI
                    </HashLink>
                  </li>
                </ul>
              </div>

              <div className="col-lg-2 col-md-3 footer-links">
                <h4>Nuestros Servicios</h4>
                <ul>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <HashLink to="/#bigData">Big Data</HashLink>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <HashLink to="/#devops">DevOps</HashLink>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <HashLink to="/#devopsAcademy">Academia DevOps</HashLink>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <HashLink to="/servicios-financieros#financialServices">
                      Consultoría Financiera
                    </HashLink>
                  </li>
                </ul>
              </div>

              <div className="col-lg-2 col-md-3 footer-links">
                <h4>Asociados a:</h4>
                <div className="logo-camespcom">
                  <a
                    href="https://camescom.mx/"
                    aria-label="duns-951588671"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <LazyLoadImage src={Camespcom} alt="camespcom" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="copyright">
            &copy;
            <strong>
              <span>Afirma Solutions</span>
            </strong>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default FooterSection;
