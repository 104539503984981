import React from "react";
import PropTypes from "prop-types";
import { LazyLoadImage } from "react-lazy-load-image-component";

import "./location-card.styles.scss"
import { EnvelopeFill, PhoneFill, TelephoneFill } from "react-bootstrap-icons";
import { Fade } from "react-reveal";


const LocationCard = (
    {
        img,
        street,
        cellphone,
        phone,
        email,
        whatsto,
        phoneto,
        mailto
    },
) => {
    return(
       <Fade bottom small cascade>
         <div className="location-container">
            <div className="img-country">
                <LazyLoadImage src={img}/>
            </div>
            <div className="info-container">
                <div className="street-container">
                    <p>{street}</p>
                </div>
                <div className="contact-icon">
                   <a href={whatsto} target="blank"> <p><PhoneFill/>{cellphone}</p></a>
                   <a href={phoneto} target="blank"> <p><TelephoneFill/>{phone}</p></a>
                   <a href={mailto} target="blank"> <p><EnvelopeFill/>{email}</p></a>
                </div>
            </div>
        </div>
       </Fade>
    )
}

LocationCard.propTypes = {
    img: PropTypes.string.isRequired,
    street: PropTypes.string.isRequired,
    cellphone: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    whatsto: PropTypes.string.isRequired,
    phoneto: PropTypes.string.isRequired,
    mailto: PropTypes.string.isRequired,
};

export default LocationCard