import React from "react";
import { Fade } from "react-reveal";
import AiInfo from "../../data/AiData.json";
import AiItem from "./Ai-Item/ai-item.component";
import "./ai.styles.scss";
import AiTechnologies from "./carrousel/Ai-technologies.component";
import AiServices from "./services/ai-services.component";

const Ai = () => {
    return (
        <div className="ai-container">
            <div className="ai-items">
                <h2 className="title">Inteligencia Artificial</h2>
                <Fade bottom small cascade>
                    <div className="ai-services">
                        <AiServices services={AiInfo.services} />
                    </div>
                </Fade>
                <div className="row ai">
                    {AiInfo.data.map((value, idx) => (
                        <div
                            className="col-lg-4 col-md-6 content-item aos-init aos-animate text-center p-0"
                            key={`aiDiv-${idx}`}
                        >
                            <AiItem
                                idx={idx + 1}
                                title={value.title}
                                text={value.text}
                            />
                        </div>
                    ))}
                </div>
                <div className="logos">
                    <AiTechnologies images={AiInfo.images} />
                </div>
            </div>
        </div>
    );
};

export default Ai;
