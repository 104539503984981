import React from "react";
import PropTypes from "prop-types";
import "./devops-card-item.styles.scss";
import { Broadcast } from "react-bootstrap-icons";
import Fade from "react-reveal/Fade";

const DevopsCardItem = ({ title, text }) => {
  return (
    <Fade bottom small cascade>
      <div className="devopsCard-item">
        <Broadcast className="icons shadow p-3 mb-13bg-white rounded-circle" color="royalblue" size={70}/><br/>
        <strong>{title}</strong> <span>{text}</span>
      </div>
    </Fade>
  );
};

DevopsCardItem.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
};
export default DevopsCardItem;
