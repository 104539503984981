import React from "react";

import ContacData from "./../../data/contact.json"
import LocationCard from "./location-card/location-card.component";

import "./contac-card.styles.scss"

const ContacCard = () =>{
   return(
    <div className="contac-container">
        <div className="contac-items">
            {ContacData.data.map((contact,idx) => (
                <LocationCard
                key={`contact-${idx}`}
                img={contact.img}
                street={contact.street}
                cellphone={contact.cellphone}
                phone={contact.phone}
                email={contact.email}
                whatsto={contact.whatsto}
                phoneto={contact.phoneto}
                mailto={contact.mailto}
                />
            ))}
        </div>
    </div>
   )
}

export default ContacCard;