import React from "react";
import { Fade } from "react-reveal";
import "../terms-conditions/terms-conditions.styles.scss";
import "./sgi-policy.styles.scss";

const SGIPolicy = () => {
    return (
        <>
            <div className="terms-conditions-container container" id="sgsi-policy">
                <div className="page-header">
                    <h2>Política del SGSI</h2>
                </div>
                <div className="article-body">
                    <Fade bottom small cascade>
                        <p>
                            <b>AFIRMA SOLUTIONS</b> reconoce que la información de su propiedad y la de sus clientes,
                            así como, los activos de información y la infraestructura que la soporta son esenciales para el
                            cumplimiento de su misión y su visión, así como para la continuidad del negocio.
                        </p>
                        <p>
                            En <b>AFIRMA SOLUTIONS</b> nos comprometemos a proteger la confidencialidad, integridad y
                            disponibilidad de la información de nuestros clientes, colaboradores y socios de negocio con el
                            objetivo de la mejora continua en seguridad de la información. Satisfaciendo las necesidades
                            actuales y futuras de nuestros clientes en apego a la legislación y normatividad nacional e
                            internacional aplicable y sobre todo cumpliendo con las directrices de seguridad para sostener
                            su mejora continua en materia de Seguridad de la Información.
                        </p>
                        <p>
                            El cumplimiento de esta política, así como cualquier procedimiento o documentación incluida
                            dentro del repositorio de documentación del SGSI*, es obligatorio y atañe a
                            todo el personal de la organización.
                        </p>
                    </Fade>
                </div>
            </div>

            <div className="sgi-policy-container container" id="sgc-policy">
                <div className="page-header">
                    <h2>Política del SGC</h2>
                </div>
                <div className="article-body">
                    <Fade bottom small cascade>
                        <p>
                            En <b>AFIRMA SOLUTIONS</b>, estamos comprometidos con la excelencia en la consultoría
                            financiera y tecnológica, enfocándonos en el desarrollo, diseño de soluciones y estrategia
                            digital, con altos estándares de calidad, enfocada a identificar y solucionar las
                            necesidades de nuestros clientes, tenido personal consciente en contribuir al cumplimiento
                            de los requisitos legales, gubernamentales y normativos aplicables a la organización,
                            con la finalidad de promover la mejora continua.
                        </p>
                    </Fade>
                </div>
            </div>
        </>
    );
};

export default SGIPolicy;
