import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Fade from "react-reveal/Fade";
import CICDInfo from "../../data/CICDData.json";
import CICDItem from "../CICD-section/CICD-item/CICD-item.component";
import DevopsCardItem from "../devops-card/devops-card-item/devops-card-item.component";
import "./CICD-section.styles.scss";


const CICDSection = () => {
  return (
    <div className="cicd-container">
      <div className="cicd-items">
        <h2 className="title">CI/CD as a Services</h2>
        <p>
          Plataforma de CI/CD estandarizada con herramientas OpenSource, con
          pipelines predeterminados
        </p>
        <br />
        <br />
        <div className="row cicd">
          {CICDInfo.data.map((value, idx) => (
            <div
              className="col-lg-4 col-md-6 content-item aos-init aos-animate text-center p-0"
              key={`cicdDiv-${idx}`}
            >
              <CICDItem
                key={`cicd-${idx}`}
                idx={idx + 1}
                title={value.title}
                text={value.text}
              />
            </div>
          ))}
        </div>
        <div className="logos">
          {CICDInfo.images.map((value, idx) => (
            <div
              className="col-md-1 content-item aos-init aos-animate p-0 m-4"
              key={`cicdDiv-${idx}`}
            >
              <Fade bottom small cascade>
                <LazyLoadImage
                  key={`img-cicd-${idx}`}
                  className="d-block w-100 images"
                  src={value.src}
                  alt="First image"
                />
              </Fade>
            </div>
          ))}
        </div>
        <div className="row text-center mt-5 mb-3">
          <div className="col-md-12 col-md-12 content-item aos-init aos-animate p-0 m-4">
            <Fade bottom small cascade>
              <LazyLoadImage
                id="imageweb"
                className="d-block w-100 image-CICD"
                src={CICDInfo.cicd[0].src}
                alt="First image"
              />
              <LazyLoadImage
                id="imagemobile"
                className="d-block w-100 image-CICD"
                src={CICDInfo.cicd[1].src}
                alt="First image"
              />
            </Fade>
          </div>
        </div>
        <div className="row text-center mt-5 mb-3">
          {CICDInfo.items.map((value, idx) => (
            <div
              className="col-md-5 content-item aos-init aos-animate m-4 text-center"
              key={`itemDiv-${idx}`}
            >
              <DevopsCardItem
                key={`img-cicd-${idx}`}
                title={value.title}
                text={value.text}
              ></DevopsCardItem>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};



export default CICDSection;
