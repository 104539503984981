import React from "react";

import "./card-section.styles.scss";

import { LazyLoadImage } from "react-lazy-load-image-component";
import CICDItem from "../CICD-section/CICD-item/CICD-item.component";
import framework from "./../../assets/images/frameworks_devops_50.png";
import CircleCardData from "./../../data/circleCard.json";

const CardSection = () => {
  return (
    <div className="card-section-container">
      <div className="row">
        {CircleCardData.data.map((value, idx) => (
          <div
            className="col-lg-4 col-md-6 content-item aos-init aos-animate text-center p-0"
            key={`cicdDiv-${idx}`}
          >
            <CICDItem
              key={`cicd-${idx}`}
              idx={idx + 1}
              title={value.title}
              text={value.text}
            />
          </div>
        ))}
      </div>
      <div className="logos">
        <LazyLoadImage src={framework} />
      </div>
    </div>
  );
};

export default CardSection;
