import React from "react";
import PropTypes from "prop-types";
import "./feature-item.styles.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {
  Chat,
  HandThumbsUpFill,
  Lightbulb,
  PeopleFill,
  ChatLeftDots,
  Globe,
  Broadcast,
  BoundingBox
} from "react-bootstrap-icons";
import Fade from "react-reveal/Fade";

function validationImage(img) {
  return img.includes("images/cabeceras/") ? 'image' : img;
}

function listView(feature) {
  let listF = Object.assign([],feature);
    return(
       <div className="text-content">
        {
            listF.map(el => <div className="text-card" key={el}>{el}</div>)
        }
       </div>
    );
}

function returnIcon(img) {
  var validation = validationImage(img);
  switch (validation) {
    case "chat":
      return <Chat />;
    case "light":
      return <Lightbulb />;
    case "people":
      return <PeopleFill />;
    case "like":
      return <HandThumbsUpFill />;
    case "ChatLeftDots":
      return <ChatLeftDots className="icons shadow p-3 mb-5 bg-white rounded-4" size={70}/>;
    case "Globe":
      return <Globe className="icons shadow p-3 mb-5 bg-white rounded-circle" color="royalblue" size={70}/>;
    case "Broadcast":
      return <Broadcast className="icons shadow p-3 mb-5 bg-white rounded-circle" color="royalblue" size={70}/>;
    case "BoundingBox":
      return <BoundingBox className="icons shadow p-3 mb-5 bg-white rounded-4" color="royalblue" size={70}/>;
    case "image":
      return <LazyLoadImage src={img} height="120" className="imgItem"/>;
    default:
      return "neutral";
  }
}

const FeatureItem = ({ img, title, text, flag, features }) => {
  return (
    <Fade bottom small cascade>
      <div className="feature-item">
        {returnIcon(img)}
        <h2>{title}</h2>
        {!flag ? <div className="line"></div> : null}
        {!flag ?<p>{text}</p>: listView(features)}
      </div>
    </Fade>
  );
};

FeatureItem.propTypes = {
  img: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  flag: PropTypes.bool,
  features: PropTypes.array,
};
export default FeatureItem;
