import PropTypes from "prop-types";
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "./description-card.styles.scss";
import Fade from "react-reveal/Fade";

function listView(feature) {
   let listF = Object.assign([],feature);
    return(
       <div className="feature-content">
        {
            listF.map(el => <p className="feature-card" key={el}><LazyLoadImage src="images/plus.png"></LazyLoadImage>{el}</p>)
        }
       </div>
    );
   
    
}

const DescriptionCard = ({
    title,
    description,
    features,
    img,
    position
},
) => {
    return (
        <Fade bottom small cascade>
            <div className="card-description-container">
            <div className={`info-card ${position?"izquierda" : "derecha"}`}>
            <h1 className="title-card">{title}</h1>
            <p className="description-card">{description}</p>
            {listView(features)}
            </div>
            <div className={`image-card ${position? "derecha" : "izquierda"}`}>
                <LazyLoadImage src={img}></LazyLoadImage>
            </div>
            </div>
        </Fade>
    );
};

DescriptionCard.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    features: PropTypes.array,
    img: PropTypes.string.isRequired,
    position: PropTypes.bool.isRequired,
};

export default DescriptionCard;