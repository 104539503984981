import React from "react";
import GalleryData from "../../../data/clientsData.json";
import GalleryItem from "../gallery-item/gallery-item.component";
import "./gallery-partners.scss";
const GalleryPartners = () => {
  return (
    <div className="gallery-partners-container">
      {GalleryData.partners.map((gallery, idx) => (
        <GalleryItem
          key={`gallery-${idx}`}
          name={gallery.name}
          img={gallery.img}
        />
      ))}
    </div>
  );
};

export default GalleryPartners;
