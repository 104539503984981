import PropTypes from "prop-types";
import React from "react";
import Fade from "react-reveal/Fade";
import "./ai-item.styles.scss";

const AiItem = ({ title, text }) => {
    return (
        <Fade bottom normal cascade>
            <div className="ai-item">
                <h4>{title}</h4>
                <div className="text-ai-item">{text}</div>
            </div>
        </Fade>
    );
};

AiItem.propTypes = {
    idx: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
};
export default AiItem;
